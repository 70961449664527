// Functions

(function ($) {

    $(document).ready(function () {
        const $accordionItems = $(".accordion-item");
    
        $accordionItems.each(function () {
            const $item = $(this);
            const $title = $item.find(".accordion-title");
            const $content = $item.find(".accordion-content");
    
            $title.on("click", function () {
                const isOpen = $item.hasClass("active");
    
                // Fermer tous les autres éléments
                $accordionItems.removeClass("active").find(".accordion-content").css({
                    transform: "scaleY(0)",
                    opacity: 0,
                    visibility: "hidden",
                    padding: 0,
                });
    
                // Ouvrir l'élément cliqué
                if (!isOpen) {
                    $item.addClass("active");
                    $content.css({
                        transform: "scaleY(1)",
                        opacity: 1,
                        visibility: "visible",
                        padding: "15px",
                    });
                }
            });
        });
    });

    $.fn.anchorBlock = function (options) {
        let settings = $.extend({
            linksContainer: '.anchor-block__links',
            contentContainer: '.anchor-block__content',
            activeLinkClass: 'is-active',
            useStickyPlugin: false, // https://cdn.rawgit.com/leafo/sticky-kit/v1.1.2/jquery.sticky-kit.min.js
            topSpacing: 0,
            stickyPluginOffsetTop: 0
        }, options);

        let el            = $(this),
            anchors       = {},
            lastScrollTop = 0,
            activeLink, activeLinkID, activeContent;

        /**
         * Is the element is visible in the viewport ?
         *
         * @returns {boolean}
         */
        $.fn.visibleInViewPort = function () {
            let el              = $(this),
                topOfElement    = el.offset().top,
                bottomOfElement = topOfElement + el.outerHeight(),
                bottomOfScreen  = $(window).scrollTop() + $(window).innerHeight(),
                topOfScreen     = $(window).scrollTop();

            if ((bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement)) return true;
            return false;
        };

        /**
         * Init the anchor
         */
        let init = () => {
            if (el.length > 0) {
                // Stick in parent links
                if (settings.useStickyPlugin) $(settings.linksContainer).stick_in_parent({offset_top: settings.stickyPluginOffsetTop});

                // Get anchors in an array
                getAnchors();

                // On load comportment
                $(window).on('load', activateLink);

                // Scroll comportment
                $(window).on('scroll', getActualScrolledID);
            }
        };

        /**
         * Activate link
         *
         * 1 - First one if not in the anchor block
         * 2 - Good one if on the anchor block
         */
        let activateLink = () => {
            if (!el.visibleInViewPort()) {
                /* 1 - First one if not in the anchor block*/

                // Activate the first link
                let firstLink = el.find($(settings.linksContainer)).find('a').first();

                // Set up some global variables to find out the active link
                // & the active ID
                // & the active content element
                firstLink.parent().addClass(settings.activeLinkClass);
                activeLink = firstLink;
                activeLinkID = activeLink.attr('href').replace('#', '');
                activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);
            } else if (el.visibleInViewPort()) {
                /* 2 - Good one if on the anchor block */

                let scrollPos = $(window).scrollTop(),
                    positions = {};

                for (let id in anchors) {
                    let element             = el.find($(settings.contentContainer)).find(`[id="${id}"]`),
                        elementHeight       = element.outerHeight(),
                        elementOffsetTop    = element.offset().top,
                        position            = ((elementOffsetTop - 60)) - scrollPos;

                    positions[id] = position;
                }

                let closest = 0,
                    closestID;

                // Get the closest number to 0 (it is the good one to activate)
                for (let id in positions) {
                    if (closest === 0) {
                        closest = positions[id];
                        closestID = id;
                    } else if (positions[id] > 0 && positions[id] <= Math.abs(closest)) {
                        closest = positions[id];
                        closestID = id;
                    } else if (positions[id] < 0 && -positions[id] < Math.abs(closest)) {
                        closest = positions[id];
                        closestID = id;
                    }
                }

                // Activate the good link
                let link = anchors[closestID];

                link.parent().addClass(settings.activeLinkClass);
                activeLink = link;
                activeLinkID = closestID;
                activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                // Add hash to URL
                history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
            }
        };

        /**
         * Get anchors & add on click event (smooth scroll)
         *
         * stored as [ id => element ]
         */
        let getAnchors = () => {
            el.find($(settings.linksContainer)).find('a').each(function () {
                let anchor = $(this.hash),
                    _this = $(this);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");

                $(this).on('click', function () {
                    if ( anchor.length ) {
                        $("html, body").stop().animate( { scrollTop: (anchor.offset().top - settings.topSpacing) }, 500, function () {
                            activeLink = _this;
                            activeLinkID = _this.attr('href').replace('#', '');
                            activeContent = anchor;
                            $(settings.linksContainer).find('.' + settings.activeLinkClass).removeClass(settings.activeLinkClass);
                            activeLink.parent().addClass(settings.activeLinkClass);
                        });
                        return false; // Bugfix Jump issue
                    }
                });

                let ID = $(this).attr('href').replace('#', '');

                anchors[ID] = $(this);
            });
        };

        /**
         * Get next link
         *
         * @param ID
         * @returns {boolean|string|*}
         */
        let getNextLink = (ID) => {
            let IDs = Object.keys(anchors),
                i   = IDs.indexOf(ID),
                l   = 0;

            for (let j in anchors) {
                if (i + 1 === l) return {id: j, link: anchors[j]};
                l++;
            }

            return false;
        };

        /**
         * Get prev link
         *
         * @param ID
         * @returns {boolean|string|*}
         */
        let getPrevLink = (ID) => {
            let IDs = Object.keys(anchors),
                i   = IDs.indexOf(ID) - 1,
                l   = 0;

            for (let j in anchors) {
                if (i === l) return {id: j, link: anchors[j]};
                l++;
            }

            return false;
        };

        /**
         * Get the actual scrolled item ID
         */
        let getActualScrolledID = () => {
            // Check if container has been scrolled past
            if (el.visibleInViewPort() && activeContent) {
                let scrollTop             = $(window).scrollTop(),
                    scrollPos             = $(document).scrollTop(),
                    activeContentPosition = activeContent.offset().top - 60,
                    activeContentHeight   = activeContent.outerHeight();

                if (scrollTop > lastScrollTop) {
                    // Downscroll
                    if (scrollPos >= (activeContentPosition + activeContentHeight)) {
                        // If active element has been passed
                        let nextLink = getNextLink(activeLinkID);

                        if (nextLink !== false) {
                            activeLink.parent().removeClass(settings.activeLinkClass);

                            activeLink = nextLink.link;
                            activeLinkID = nextLink.id;
                            activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                            activeLink.parent().addClass(settings.activeLinkClass);

                            // Add hash to URL
                            history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
                        }
                    }
                } else {
                    // Upscroll
                    if (scrollPos <= activeContentPosition) {
                        // If active element has been passed
                        let prevLink = getPrevLink(activeLinkID);

                        if (prevLink !== false) {
                            activeLink.parent().removeClass(settings.activeLinkClass);

                            activeLink = prevLink.link;
                            activeLinkID = prevLink.id;
                            activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                            activeLink.parent().addClass(settings.activeLinkClass);

                            // Add hash to URL
                            history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
                        }
                    }

                }

                lastScrollTop = scrollTop;
            }
        };

        init();
    };

    const headers = document.querySelectorAll('.accordion-header');

    headers.forEach((header) => {
        header.addEventListener('click', function () {
            const item = this.parentElement;

            // Fermer tous les autres
            const allItems = document.querySelectorAll('.accordion-item');
            allItems.forEach((otherItem) => {
                if (otherItem !== item) {
                    otherItem.classList.remove('active');
                    otherItem.querySelector('.accordion-content').style.maxHeight = null;
                }
            });

            // Basculer l'état de l'élément cliqué
            item.classList.toggle('active');
            const content = item.querySelector('.accordion-content');
            if (item.classList.contains('active')) {
                content.style.maxHeight = content.scrollHeight + 'px';
            } else {
                content.style.maxHeight = null;
            }
        });
    });


// Wrap block with a tag
function wrap_block(el) {
    var element = $('.' + el);
    if (element.length > 0) {
        element.each(function () {
            $(this).wrap($(this).find('a').first().clone().html(''));
        });
    }
}

/**
 * Detect if the device is a touch device (tablet, mobile, ...)
 *
 * @returns {boolean}
 */
function isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
}

// Autosize textarea
function autosize(el) {
    setTimeout(function () {
        el.style.cssText = 'height:auto; padding:0';
        el.style.cssText = 'height:' + parseInt(el.scrollHeight + 4) + 'px';
    }, 0);
}

// Open / Close Hamburger menu
function toggleHamburgerMenu() {
    var hamburger_menu = $('.hamburger-menu');

    if (!hamburger_menu.hasClass('is-active')) {
        $('body').css({
            'position': 'absolute',
            'right': hamburger_menu.outerWidth()
        });
        $('.header-menu__sticky').css('transform', 'translate3d(-' + hamburger_menu.outerWidth() + 'px, -100%, 0)');
        hamburger_menu.css('right', 0);
    } else {
        $('body').css({
            'position': 'absolute',
            'right': 0
        });
        $('.header-menu__sticky').css('transform', '');
        hamburger_menu.css('right', (hamburger_menu.outerWidth() + 20) * -1);
    }

    hamburger_menu.toggleClass('is-active');
    $('.overlay__white').fadeToggle();
}

// Move navs arrows below title of slider prices below 900px
function moveArrowTopSliderPrice(carousel, window_width) {
    var owl_nav = carousel.find('.owl-nav:last');

    carousel.find('.bloc-title').each(function () {
        if ($(this).parent().find('.owl-nav').length == 0) {
            owl_nav.clone().addClass('owl-nav-custom-price').css('margin-top', 15).insertAfter($(this));
        }
    });
    if (window_width <= 900) {
        carousel.find('.owl-nav:not(:last)').show();
        owl_nav.hide();
    } else {
        owl_nav.show().css('margin', 0);
        carousel.find('.owl-nav:not(:last)').hide();
    }
}

// Set up the height of blocks of clinics page
function setHeightClinicsBlocks() {
    $('.clinics > a').each(function () {
        var clinic_text = $(this).find('.clinic__text');
        var paddings = parseInt(clinic_text.css('padding-top')) + parseInt(clinic_text.css('padding-bottom'));
        var clinic_text_height = clinic_text.children().height() + paddings;

        if (clinic_text.hasClass('clinic__text--is-active')) {
            clinic_text_height += 4;
        }

        clinic_text.outerHeight(clinic_text_height);
    });
}

// Is IE 11 browser ?
function isIE11() {
    return !!navigator.userAgent.match(/Trident.*rv\:11\./);
}

// Set up height of blocks (text in (IE FIX : height : max-content))
function setHeightBlocksText() {
    if (isIE11()) {
        if ($(window).width() <= 1050 || !$('body').hasClass('home')) {
            $('.blocks-container > a').each(function () {
                var block_text = $(this).find('.block-container__block--text');
                var inner_height = block_text.children().height();

                block_text.outerHeight(inner_height);
            });
        } else {
            $('.blocks-container > a').each(function () {
                $(this).find('.block-container__block--text').removeAttr('style');
            });
        }
    }
}

// Set Width of Clinics blocks
function setWidthClinics() {
    if (isIE11()) {
        $('.clinics > a').each(function () {
            $(this).css({
                'max-width': $(this).find('img').width(),
                'width': '100%'
            });
        });
    }
}

// Set width of an element
function setWidth(element) {
    element.css('width', element.outerWidth());
}

// Init sticky mnu
function stickyMenuInit() {
    $('.header-menu').clone().addClass('header-menu__sticky').insertAfter($('.header-menu'));
}

// Make the menu sticky
function stickyMenu() {
    var menu = $('.header-menu__sticky'),
        scroll = $(window).scrollTop();

    if (scroll > 500) {
        menu.css('top', menu.outerHeight());
    } else {
        menu.css('top', -10);
    }
}

// Create a div where needed
function createDiv(className, append) {
    var output = '<div class="' + className + '"></div>';
    $(output).appendTo(append);
}

// Init the app
function init() {
    wrap_block('block-container__block');
    wrap_block('clinic');

    // Set up side blocks
    $('.hamburger-menu').css('right', ($('.hamburger-menu').outerWidth() + 20) * -1);

    // If there is more content (overlap text), add the plus button
    if ($('.overlap-text__more-content').length > 0) {
        createDiv('overlap-text__more-content__button', $('.overlap-text__more-content'));
    }

    setHeightClinicsBlocks();
    setHeightBlocksText();
    setWidthClinics();
    stickyMenuInit();
}


$(document).ready(function () {
    // On resize
    
    $('.input-control input').on('focus', function() {
        if (!$(this).hasClass('input--focused')) {
        $(this).addClass('input--focused');    
        }
    }).on('blur', function() {
        if($(this).val() === '' && $(this).hasClass('input--focused')) {
        $(this).removeClass('input--focused')
        }  
    }) 

    $('.input-control textarea').on('focus', function() {
        if (!$(this).hasClass('input--focused')) {
        $(this).addClass('input--focused');    
        }
    }).on('blur', function() {
        if($(this).val() === '' && $(this).hasClass('input--focused')) {
        $(this).removeClass('input--focused')
        }  
    }) 
    validateForm();
    var home_carousel_prices = $('.home-slider-prices');
    $(window).on('resize', function () {
        setHeightClinicsBlocks();
        setHeightBlocksText();
        moveArrowTopSliderPrice(home_carousel_prices, $(document).width());
    });

    var widget_appointment = $('.widget-appointment');
    widget_appointment.css({
        '-webkit-transform': 'translateX(' + (widget_appointment.find('p').width() + 15) + 'px)',
        '-ms-transform': 'translateX(' + (widget_appointment.find('p').width() + 15) + 'px)',
        'transform': 'translateX(' + (widget_appointment.find('p').width() + 15) + 'px)',
        'padding-right': 15
    });

    $('.single-page__post--title').attr('style', 'font-size: 32px !important;');

    // On scroll
    $(document).scroll(function () {
        // STICKY MENU
        stickyMenu();

        var scroll = $(this).scrollTop();

        // BACK TO TOP
        if (scroll > 800) {
            $('.back-to-top').css({
                'transform': 'none',
                'right': 15
            });
        } else {
            $('.back-to-top').css({
                'transform': '',
                'right': ''
            });
        }
    });


    // Globals variables
    var previousClick = null;

    // Init functions
    init();


    // On click on "Menu" (Hamburger responsive header)
    $('.header-menu-responsive__bottom-header__menu').on('click', function () {
        $(this).find('.hamburger').toggleClass('is-active');
        $('body').find('.menu-header-menu-responsive-container').slideToggle();
        $('.overlay__black').fadeToggle();
    });

    // Open the hamburger menu on click, and close it on the other click
    $('.header-menu__sidemenu > .hamburger, .hamburger-menu__close').on('click', function () {
        toggleHamburgerMenu();
    });

    // Close the hamburger menu on click on the overlay
    $('.overlay__white').on('click', function () {
        toggleHamburgerMenu();
    });

    // Auto resize textareas
    var textarea = document.querySelectorAll('textarea');

    for (var i = 0; i < textarea.length; i++) {
        if (textarea[i] !== null) {
            textarea[i].addEventListener('keydown', function (e) {
                var key = e.which || e.keyCode;
                if (key === 13 || key === 8) {
                    autosize(this);
                }
            });
        }
    }

    // Submit form
    $('#submit-contact-form').on('click', function () {
        $(this).parentsUntil('form').submit();
    });

    // Custom file upload
    $('.file-custom__button').on('click', function () {
        $(this).parent().find('input[type=file]').click();
    });

    // On click on home blocks, if is touch device,
    // wait the second click to go on the page
    // Same for clinics page
    $('.blocks-container > a, .clinics > a').on('click', function (e) {
        if (isTouchDevice()) {
            if (previousClick !== this) {
                e.preventDefault();
            }
        }
        previousClick = this;
    });

    // Responsive Menu Header - display the sub menu when click on the '>'
    $('.header-menu-responsive__menu > .menu-item-has-children > a').on('click', function (e) {
        e.preventDefault();
        $(this).parent().find('.sub-menu').first().slideToggle();
        $(this).parent().toggleClass('is-active');
    });

    $('.header-menu-responsive__menu > .menu-item-has-children > a > span').on('click', function (e) {
        e.stopPropagation();
    });

    $('.menu-title-children > a').on('click', function (e) {
        e.preventDefault();
        $(this).parent().find('.sub-menu').first().slideToggle();
        $(this).parent().toggleClass('is-active');
    });

    $('.menu-title-children > a > span').on('click', function (e) {
        e.stopPropagation();
    });

    // Home Carousel Prices
    home_carousel_prices.owlCarousel({
        items: 1,
        loop: true,
        dots: true,
        nav: true,
        navText: ['<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 31.494 31.494" style="enable-background:new 0 0 31.494 31.494;" xml:space="preserve"><path d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"/></svg>','<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 31.49 31.49" style="enable-background:new 0 0 31.49 31.49;" xml:space="preserve"><path d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111  C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587  c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"/></svg>'],
    });
    // Lower than 900px, add arrows after title
    moveArrowTopSliderPrice(home_carousel_prices, $(document).width());
    // Custom arrows jquery
    $('.owl-nav.owl-nav-custom-price .owl-next').on('click', function () {
        home_carousel_prices.trigger('next.owl.carousel');
    });
    $('.owl-nav.owl-nav-custom-price .owl-prev').on('click', function () {
        home_carousel_prices.trigger('prev.owl.carousel');
    });

    // Internal page blocks container hover comportment
    $('.internal-page-container .block-container__block').hover(function () {
        $(this).find('.block-container__block--text__title--subline').toggleClass('hidden-after');
        $(this).find('.block-container__block--text__hidden').toggleClass('hidden');
    });

    // Clinics block, hover comportment
    $('.clinic').hover(function () {
        $(this).find('.clinic__text--hidden').toggleClass('hidden');
        $(this).find('.clinic__text').toggleClass('clinic__text--is-active');
        setHeightClinicsBlocks();
    });

    // If there is more hidden text in overlap, group them in one div
    if ($('.overlap-text__inner--more-content').length > 1) {
        $('.overlap-text__inner--more-content').removeClass('overlap-text__inner--more-content').wrapAll('<div class="overlap-text__inner--more-content" />');
    }

    // Overlap text onclick, show hidden text
    $('.overlap-text__more-content__button').on('click', function () {
        var el = $(this);

        $(this).parent().find('.overlap-text__inner--more-content').slideToggle(function () {
            if($(this).is(":hidden")) {
                el.removeClass('is-active');
            }
        });
        el.addClass('is-active');
    });

    // Images with desc carousel
    $('.images-desc-carousel').owlCarousel({
        items: 1,
        loop: true,
        dots: true,
        nav: true,
        navText: ['<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 31.494 31.494" style="enable-background:new 0 0 31.494 31.494;" xml:space="preserve"><path d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"/></svg>','<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 31.49 31.49" style="enable-background:new 0 0 31.49 31.49;" xml:space="preserve"><path d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111  C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587  c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"/></svg>'],
    });

    $('.before-after-carousel').owlCarousel({
        items: 1,
        loop: true,
        dots: true,
        nav: true,
        navText: ['<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 238.003 238.003" style="transform: rotate(180deg); enable-background:new 0 0 238.003 238.003;" xml:space="preserve"><path d="M181.776,107.719L78.705,4.648c-6.198-6.198-16.273-6.198-22.47,0s-6.198,16.273,0,22.47    l91.883,91.883l-91.883,91.883c-6.198,6.198-6.198,16.273,0,22.47s16.273,6.198,22.47,0l103.071-103.039    c3.146-3.146,4.672-7.246,4.64-11.283C186.416,114.902,184.89,110.833,181.776,107.719z"></path></svg>', '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 238.003 238.003" style="enable-background:new 0 0 238.003 238.003;" xml:space="preserve"><path d="M181.776,107.719L78.705,4.648c-6.198-6.198-16.273-6.198-22.47,0s-6.198,16.273,0,22.47    l91.883,91.883l-91.883,91.883c-6.198,6.198-6.198,16.273,0,22.47s16.273,6.198,22.47,0l103.071-103.039    c3.146-3.146,4.672-7.246,4.64-11.283C186.416,114.902,184.89,110.833,181.776,107.719z"></path></svg>']
    });

    // Results container carousel
    var results_carousel = $('.results-carousel');
    results_carousel.owlCarousel({
        items: 1,
        loop: true,
        dots: false,
        nav: true,
        navText: ['<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 238.003 238.003" style="transform: rotate(180deg); enable-background:new 0 0 238.003 238.003;" xml:space="preserve"><path d="M181.776,107.719L78.705,4.648c-6.198-6.198-16.273-6.198-22.47,0s-6.198,16.273,0,22.47    l91.883,91.883l-91.883,91.883c-6.198,6.198-6.198,16.273,0,22.47s16.273,6.198,22.47,0l103.071-103.039    c3.146-3.146,4.672-7.246,4.64-11.283C186.416,114.902,184.89,110.833,181.776,107.719z"></path></svg>', '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 238.003 238.003" style="enable-background:new 0 0 238.003 238.003;" xml:space="preserve"><path d="M181.776,107.719L78.705,4.648c-6.198-6.198-16.273-6.198-22.47,0s-6.198,16.273,0,22.47    l91.883,91.883l-91.883,91.883c-6.198,6.198-6.198,16.273,0,22.47s16.273,6.198,22.47,0l103.071-103.039    c3.146-3.146,4.672-7.246,4.64-11.283C186.416,114.902,184.89,110.833,181.776,107.719z"></path></svg>']
    });

    // Add arrows in images (results carousel)
    results_carousel.each(function () {
        var image_container = $(this).find('.result-container__image');
        var owl_nav = $(this).find('.owl-nav');

        owl_nav.appendTo(image_container.find('figure'));
    });

    // Back to top
    var back_to_top = $('.back-to-top');
    back_to_top.on('click', function () {
        $(this).addClass('is-hover');
        $('html,body').animate({
            scrollTop: 0
        }, 700, function () {
            back_to_top.removeClass('is-hover');
        });
    });
    back_to_top.hover(function () {
        $(this).toggleClass('is-hover');
    });

    // Search comportment
    $(function () {
        $('.header-menu-responsive__bottom-header--container svg, .header__search-button').on('click', () => {
            $('.overlay-search-bar').fadeIn();
        });
        $('.overlay-search-bar-close').on('click', () => {
            $('.overlay-search-bar').fadeOut();
        });
    });

    $('.form-control-file').on('change', function () {
        let text = this.files[0].name;

        $('.file-custom__text').html(text);
    });

    if ($('.horizontal-accordion--open-all').length > 0) {
        $('.horizontal-accordion--open-all .vc_tta-panel').each(function () {
           $(this).addClass('vc_active');
        });
    }

    // Reset file upload on mail sent
    $('.wpcf7').each(function () {
       $(this).on('wpcf7mailsent', function (event) {
           $(this).find('.form-control-file').val('');
           $(this).find('.file-custom__text').html('Aucun fichier choisi');
       });
    });

    $('.anchor-block').anchorBlock({
        linksContainer: '.vc_tta-tabs-list',
        contentContainer: '.vc_tta-panels',
        activeLinkClass: 'vc_active',
        topSpacing: 120
    });

    $('.vc_tta-panel-title').each(function() {
        var pElement = $('<p></p>');

        pElement.html($(this).html());

        $.each(this.attributes, function() {
            pElement.attr(this.name, this.value);
        });

        $(this).replaceWith(pElement);
    });


    // Popup Fermeture Cabinet
    // function _hasCookies(cookieName){
    //     if(document.cookie.indexOf(cookieName+"=true") > -1){
    //         return true;
    //     } else if(document.cookie.indexOf(cookieName+"=false") > -1){
    //         return false;
    //     }
    //     return null;
    // }
    // function _setCookies(name, value, minutes) {
    //     var d = new Date();
    //     d.setTime(d.getTime() + (minutes * 60 * 1000));
    //     var expires = "expires="+d.toUTCString();
    //     document.cookie = name + "=" + value + ";" + expires + ";path=/";
    // }

    // if (_hasCookies('PopupCabinetClosed') == null) {
    //   setTimeout(function () {
    //     $('.popup_closeinfos, .popup_closeinfos__overlay').fadeIn(400);
    //   }, 1000);
    // }

    // $(document).on('click', '.popup_closeinfos__close', function(event) {
    //   $('.popup_closeinfos, .popup_closeinfos__overlay').fadeOut('400');
    //   _setCookies("PopupCabinetClosed",true,60);
    //   event.preventDefault();
    // });
    function validateForm() {
        const form = document.querySelector('#comment-form');
        if (!form) {
            return;
        }

        const nameEL = document.querySelector('#author');
        const commentEL = document.querySelector('#comment');
        const emailEL = document.querySelector('#email');
        const isRequired = value => value === '' ? false : true;
        const isEmailValid = (email) => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        };
        const showError = (formField) => {
            formField.parentElement.classList.remove('success');
            formField.parentElement.classList.add('error');
        };
        const showSuccess = (formField) => {
            formField.parentElement.classList.remove('error');
            formField.parentElement.classList.add('success');
        };

        const checkEmail = () => {
            let valid = false;
            const email = emailEL.value.trim();
            if (!isEmailValid(email)) {
                showError(emailEL)
            } else {
                showSuccess(emailEL);
                valid = true;
            }
            return valid;
        };

        const checkUsername = () => {
            let valid = false;
            const name = nameEL.value.trim();

            if (!isRequired(name)) {
                showError(nameEL);
            } else {
                showSuccess(nameEL);
                valid = true;
            }
            return valid;
        }

        const checkComment = () => {
            let valid = false;
            const comment = commentEL.value.trim();

            if (!isRequired(comment)) {
                showError(commentEL);
            } else {
                showSuccess(commentEL);
                valid = true;
            }
            return valid;
        }

        form.setAttribute('novalidate', true);

        form.addEventListener("submit", (event) => {
            event.preventDefault();

            let isUsernameValid = checkUsername(),
                isEmailValid = checkEmail(),
                isCommentValid = checkComment();

            let isFormValid = isUsernameValid &&
                isEmailValid &&
                isCommentValid;

            if (isFormValid) {
                const submitFormFunction = Object.getPrototypeOf(form).submit;
                submitFormFunction.call(form);
            }
        });
    }
});

} )( jQuery );