import { unwrap, wrap } from '@antipodes-medical/js-helpers';

export default class MaterialInput {

	static init(settings = {}) {
		this.settings = {
			textarea: {
				minHeight: 50
			}
		};

		this._setupSettings(settings);

		this._autoGrowTextareas();
		this._materialInput();
	}

	/**
	 * Set up settings defined in the creation of the instance
	 *
	 * @param settings
	 * @private
	 */
	static _setupSettings(settings) {
		if (Object.keys(settings).length > 0) {
			for (const key in settings) {
				if (settings.hasOwnProperty(key) && this.settings.hasOwnProperty(key)) {
					this.settings[key] = settings[key];
				}
			}
		}
	}

	/**
	 * Auto grow textareas material input
	 *
	 * @private
	 */
	static _autoGrowTextareas() {
		const $autoGrowTextareas = document.querySelectorAll('.materialInput textarea');

		const calcHeight = ($textarea, minHeight) => {
			//@formatter:off
			const numberOfLineBreaks = ($textarea.value.match(/\n/g) || []).length;
			//@formatter:on
			const styles = window.getComputedStyle($textarea);
			// min-height + lines x line-height + padding + border
			return minHeight + numberOfLineBreaks * parseFloat(styles.getPropertyValue('line-height'));
		};

		$autoGrowTextareas.forEach($autoGrowTextarea => {
			$autoGrowTextarea.addEventListener('keyup', () => {
				const minHeight = this.settings.textarea.minHeight;
				const height = calcHeight($autoGrowTextarea, minHeight);

				$autoGrowTextarea.style.height = `${height}px`;
				$autoGrowTextarea.parentNode.style.height = `${height}px`;
				$autoGrowTextarea.parentNode.style.setProperty('--materialInputExtraHeight', `${height - minHeight}px`);
				if (height === minHeight) {
					setTimeout(() => {
						$autoGrowTextarea.parentNode.querySelector('label span').style.transition = '';
					}, 100);
				} else {
					$autoGrowTextarea.parentNode.querySelector('label span').style.transition = 'none';
				}
			});
		});
	}

	/**
	 * Material input
	 *
	 * @private
	 */
	static _materialInput() {
		const $materialInputs = document.querySelectorAll('.materialInput');

		if (!$materialInputs) {
			return;
		}

		$materialInputs.forEach($materialInput => {
			const $input = $materialInput.querySelector('input, textarea');

			const $span = $materialInput.querySelector('span.wpcf7-form-control-wrap');

			if ($span) {
				unwrap($span);
				wrap($materialInput, $span);
			}

			$input.addEventListener('blur', () => {
				if ($input.value) {
					$input.classList.add('isActive');
				} else {
					$input.classList.remove('isActive');
				}
			});
		});
	}
}